<template>
  <div class="sample_plan_review">
    <h2>Sample Plan Review - Plan {{ plan_id }}</h2>
    <b-card class="mt-3 text-left">
      <b-row>
        <b-col>
          <strong>PWS:</strong> {{ pws_id }} {{ pws_name }}
        </b-col>
        <b-col>
          <strong>PWS Type:</strong> {{ pws_type }}
        </b-col>
        <b-col>
          <strong>Population:</strong> {{ population }}
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <strong>Samples Required:</strong>
            {{ sample_count }}
        </b-col>
        <b-col>
        </b-col>
        <b-col>
          
        </b-col>
      </b-row>

          <b-alert :show="data_loaded && !sp_count_ok" variant="danger">
            Active Sample Point Count ({{ active_sp_count }}) is less than required ({{ sample_count }}).
          </b-alert>

      <b-row class="mt-2">
        <b-col>
          <strong>Plan Type:</strong> {{ plan_type }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <strong>Signed By:</strong> {{ data_signame }}
        </b-col>
        <b-col>
          <strong>Email:</strong>
          {{ portal_user_email }}
          <b-button size="sm" variant="outline-primary" @click="launchEmail">Send E-mail</b-button>
        </b-col>
        <b-col>
          <strong>Submitted:</strong> {{ submission_ts }}
        </b-col>
      </b-row>
      <b-row>
        <b-col><strong>Reviewed By:</strong> {{ reviewer }}</b-col>
        <b-col><strong>Reviewed:</strong> {{ review_updt_ts }}</b-col>
        <b-col></b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
        <strong>Plan Comments:</strong>
        <p>{{ plan_comments }}</p>
      </b-col>

        <b-col v-if="plan_type == 'RTCR3'">
        <a :href="sop_url">Download SOP File</a>
      </b-col>
        <b-col></b-col>

      </b-row>
      <b-form class="mt-2" @submit="onSubmit">
          <b-form-group
            label="Status"
          >
          <b-form-select v-model="sample_plan_status" :options="status_options">
          </b-form-select>
        </b-form-group>

      <b-form-group
        label="Review Comments"
      >
      <b-form-textarea
        v-model="comments"
        rows="3"
      ></b-form-textarea>
    </b-form-group>
    <b-button type="submit" variant="primary">Save Review</b-button>
    <b-alert class="mt-2"
      @dismiss-count-down="countDownChanged"
      :show="dismiss_countdown"
      variant="success" fade>Review Saved</b-alert>
  </b-form>

  </b-card>

    <div class="mt-3 text-left"> 
    <b-button variant="primary" @click="showMap">
      <b-icon-map />Show Map
    </b-button>
    </div>
      <b-modal
        id="gmap-modal"
        ref="gmap-modal"
        title="Routine Sample Sites"
        size="xl"
      >
      <GmapMap
        ref="spmap"
        :center="map_center"
        :zoom="12"
        map-type-id="terrain"
        :options="{ gestureHandling: 'greedy' }"
        style="width: 100%; height: 60vh"
      >
        <GmapMarker
          :key="index"
          :label="spint(m.title)"
          :title="m.address"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="false"
          :draggable="false"
          @click="center = m.position"
        />
      </GmapMap>
    </b-modal>

    <div class="mb-3 mt-3">
      <b-table
        primary-key="sp_id"
        ref="sample_points_table"
        striped
        bordered
        responsive
        :fields="table_fields"
        :items="sample_plan.sp_list"
        :tbody-tr-class="rowClass"
      >

      <template #cell(sp_id)="data">
        <span v-if="data.item.new_point.edited && data.item.new_point.active">
          <span class="text-success">
            <strong>{{ data.item.sp_id }}</strong>
          </span>
        </span>
        <span v-else>
          {{ data.item.sp_id }}
        </span>
      </template>

      <template #cell(new_point.active)="data">
        <span v-if="data.item.new_point.active">
          <b-icon-check-square />
        </span>
        <span v-else>
          <b-icon-square />
        </span>
      </template>

      <template #cell(new_point.address)="data">
        <span v-if="data.item.ss_point.note_1 !== data.item.new_point.address">
          <span class="text-danger">
            <strike>{{ data.item.ss_point.note_1 }}</strike>
          </span>
          <br />
          <span class="text-success">{{ data.item.new_point.address }}</span>
        </span>
        <span v-else>
          {{ data.item.new_point.address }}
        </span>
      </template>

      <template #cell(new_point.city)="data">
        <span v-if="data.item.ss_point.city !== data.item.new_point.city">
          <span class="text-danger">
            <strike>{{ data.item.ss_point.city }}</strike>
          </span>
          <br />
          <span class="text-success">{{ data.item.new_point.city }}</span>
        </span>
        <span v-else>
          {{ data.item.new_point.city }}
        </span>
      </template>

      <template #cell(new_point.zipcode)="data">
        <span v-if="data.item.ss_point.zipcode !== data.item.new_point.zipcode">
          <span class="text-danger">
            <strike>{{ data.item.ss_point.zipcode }}</strike>
          </span>
          <br />
          <span class="text-success">{{ data.item.new_point.zipcode }}</span>
        </span>
        <span v-else>
          {{ data.item.new_point.zipcode }}
        </span>
      </template>

      <template #cell(new_point.note)="data">
        <span v-if="data.item.ss_point.note_3 !== data.item.new_point.note">
          <span class="text-danger">
            <strike>{{ data.item.ss_point.note_3 }}</strike>
          </span>
          <br />
          <span class="text-success">{{ data.item.new_point.note }}</span>
        </span>
        <span v-else>
          {{ data.item.new_point.note }}
        </span>
      </template>


      <template #cell(new_point.description)="data">
        <span v-if="data.item.ss_point.description_text !== data.item.new_point.description">
          <span class="text-danger">
            <strike>{{ data.item.ss_point.description_text }}</strike>
          </span>
          <br />
          <span class="text-success">{{ data.item.new_point.description }}</span>
        </span>
        <span v-else>
          {{ data.item.new_point.description }}
        </span>
      </template>

      </b-table>
    </div>
  </div>
</template>
<script>
import Vue from "vue"
import * as VueGoogleMaps from "vue2-google-maps"
const average = (array) => array.reduce((a, b) => a + b) / array.length

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDcZNa-cxgUyA6Hfqr772Sevv5XdbOpqFs"
  }
})

export default {
  data() {
    return {
      data_loaded: false,
      table_fields: [
        { key: "sp_id", label: "ID" },
        { key: "new_point.active", label: "Active" },
        { key: "new_point.address", label: "Address" },
        { key: "new_point.city", label: "City" },
        { key: "new_point.zipcode", label: "Zip" },
        { key: "new_point.note", label: "Note" },
        { key: "new_point.description", label: "Description" },
      ],
      sample_plan: {},
      plan_id: null,
      comments: '',
      reviewer: '',
      portal_user_email: '',
      review_updt_ts: null,
      pws_id: '',
      pws_name: '',
      pws_type: '',
      population: null,
      sample_count: null,
      plan_type: '',
      sop_url: null,
      plan_comments: '',
      data_signame: '',
      submission_ts: '',
      sample_plan_status: 'N',
      //status_options: [
      //  { value: 'N', text: 'New'},
      //  { value: 'U', text: 'Under Review'},
      //  { value: 'A', text: 'Approved'},
      //  { value: 'F', text: 'Followup Required'},
      //  { value: 'R', text: 'Rejected'},
      //],
      status_options: [
        { value: "N", text: "Not Reviewed" },
        { value: "A", text: "Reviewed" },
        { value: "F", text: "Followup Required" },
        { value: "R", text: "Rejected" },
      ],
      dismiss_countdown: 0,
      dismiss_seconds: 1,
      // Map
      //show_map: true,
      markers: [],
      map_center: { lat: 0, lng: 0}
    }
  },
  created() {
    console.log('created')
    console.log(this.$route.params)
    this.plan_id = this.$route.params.plan_id
  },
  mounted() {
    //pass
    this.fetchSamplePlanData()
    //setTimeout(this.onShow, 2000)
  },
  computed: {
    active_sp_count: function() {
      let sp_count = 0
      if (this.sample_plan && this.sample_plan.sp_list) {
        sp_count = this.sample_plan.sp_list.filter(sp => sp.new_point.active || sp.new_point.new).length
      }
      return sp_count
    },
    sp_count_ok: function() {
      if (this.sample_count && this.sample_count <= this.active_sp_count) {
        return true
      }
      return false
    },
  },
  methods: {
    countDownChanged(dismiss_countdown) {
      this.dismiss_countdown = dismiss_countdown
    },
    showAlert() {
      this.dismiss_countdown = this.dismiss_seconds
    },
    async fetchSamplePlanData() {
      const path = process.env.VUE_APP_API_URI + "/sample-plan-review"
      try {
        console.log("fetching sample plan data")
        let response = await this.axios.get(path, { params: { plan_id: this.plan_id } })
        if (response.data) {
          console.log(response.data)
          let sp = response.data.sample_plan
          this.sample_plan = sp
          this.sample_plan_status = sp.plan_data.status
          this.plan_comments = sp.plan_data.comments
          this.reviewer = sp.review.reviewer
          this.comments = sp.review.comments
          this.review_updt_ts = sp.review.updt_ts
          this.pws_id = sp.plan_data.pws_id
          this.portal_user_email = sp.plan_data.portal_user_email
          this.plan_type = sp.plan_data.plan_type
          this.data_signame = sp.plan_data.data_signame
          this.submission_ts = sp.plan_data.submission_ts
          if (sp.ws_data.population) {
            this.population = sp.ws_data.population
            this.sample_count = sp.ws_data.sample_count
            this.pws_name = sp.ws_data.pws_name
            this.pws_type= sp.ws_data.pws_type_code
          }
          // set SOP URL
          if (this.plan_type == 'RTCR3') {
            this.sop_url = process.env.VUE_APP_API_URI
            this.sop_url += "/sample-plan-sop?plan_id=" + this.plan_id
          }
          this.loadMarkers(sp.sp_list)
          this.data_loaded = true
        }
      } catch (error) {
        console.log(error)
      }
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.saveReview()
      this.showAlert()
    },
    launchEmail() {
      let address = this.portal_user_email
      let pws_id = this.pws_id
      let pws_name = this.pws_name
      let signame = this.data_signame
      let submission_ts = this.submission_ts
      let subject = `RTCR Site Plan for ${pws_name}, ID# ${pws_id}`
      let msg = "This e-mail concerns the RTCR Sample Site Plan "
      msg += `submitted by ${signame} on ${submission_ts} `
      msg += ` for the Public Water System ${pws_name}, ID# ${pws_id}.\n\n`
      msg += "View, amend, or submit plans at the MS PWS Portal https://pws.mswater.us"
      let link = 'mailto:' + address + '?subject=' + encodeURIComponent(subject) + "&body=" + encodeURIComponent(msg)
      window.location.href=link
    },
    async saveReview() {
      const username = this.$store.state.user
      console.log(username)
      const path = process.env.VUE_APP_API_URI + "/save-sample-plan-review"
      try {
        console.log("fetching sample plan data")
        let params = {
          plan_id: this.plan_id,
          status: this.sample_plan_status,
          reviewer: username,
          comments: this.comments
        }
        let response = await this.axios.post(path, params)
        if (response.data) {
          console.log(response.data)
        }
      } catch (error) {
        console.log(error)
      }

    },
    rowClass(item, type) {
      if (!item || type !== "row") return
      if (!item.new_point.active && item.ss_point.status == 'A') return "table-danger"
      else if (item.new_point.active && item.ss_point.status != 'A') return "table-success"
      else if (item.new_point.new) return "table-success"
    },
    spint(sp) {
      if (!sp) {
        return ""
      }
      if (sp.startsWith('WL')) {
        return sp
      }
      let t = sp.substr(2, 10)
      return parseInt(t).toString()
    },
    loadMarkers(sp_list) {
      let lats = []
      let lons = []
      sp_list.forEach(rset => {
        let sp = rset.new_point
        let marker = {}
        marker.title = sp.sp_id
        if (sp.latitude) {
          marker.position = { lat: sp.latitude, lng:sp.longitude }
          marker.address = "Point"
        } else if (sp.geo_latitude) {
            marker.position = { lat: sp.geo_latitude, lng: sp.geo_longitude },
            marker.address = sp.address
        }
        if (sp.active || sp.new) {
          this.markers.push(marker)
          lats.push(marker.position.lat)
          lons.push(marker.position.lng)
        }
      })
      this.map_center.lat = average(lats)
      this.map_center.lng = average(lons)

    },
    showMap() {
      this.$refs["gmap-modal"].show()
      setTimeout(this.onShow, 2000)
    },
    onShow() {
      //console.log(this.$refs.spmap)
      this.$refs.spmap.$mapPromise.then(() => {
        this.fitBounds(this.$refs.spmap)
      })
    },
    fitBounds(map) {
      console.log("fitting bounds")
      //if (!this.google_maps_initialized) return null
      let b = new window.google.maps.LatLngBounds()
      //console.log(b)
      this.markers.forEach((marker) => {
        b.extend(marker.position)
      })
      map.fitBounds(b)
    },
    //toggleMap() {
    //  console.log('toggle map')
    //  this.show_map = this.show_map ? false : true
    //  //this.fitBounds(this.$refs.spmap)
    //  if (this.show_map) {
    //    setTimeout(this.onShow, 2000)
    //  }
    //},
  }
}
</script>
